import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
    Box,
    Container,
    VStack,
    Stack,
    Image,
    Button,
    Heading,

} from "@chakra-ui/react";
import Layout from "../components/Layout";
import { createVariant, getExample, subscribeToExample, getSavedExamplesInfo, saveExample } from "../hooks/useFirebase";
import { DownloadIcon } from "@chakra-ui/icons";
import { STATUS } from "../utils";
import { useUser } from "../contexts/UserContext";
import SaveConfigModal from '../components/customization/SaveConfigModal';
import LoadConfigModal from '../components/customization/LoadConfigModal';
import CustomizationForm from '../components/customization/CustomizationForm';

function CustomizationPage() {
    const { exampleId } = useParams();
    const navigate = useNavigate();
    const userId = useUser();
    
    // Initialize config with default values for all expected fields
    const [config, setConfig] = useState({});
    
    const [example, setExample] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [saveName, setSaveName] = useState("");

    const [isLoadModalOpen, setIsLoadModalOpen] = useState(false);
    const [savedExamplesInfo, setSavedExamplesInfo] = useState([]);
    const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);

    // Fetch the example from the database
    useEffect(() => {
        if (!exampleId) return;
        
        const fetchExample = async () => {
            const fetchedExample = await getExample(exampleId);
            setExample(fetchedExample);
            
            // Merge the fetched config with defaults to ensure all fields exist
            setConfig(prevConfig => ({
                ...prevConfig,
                ...(fetchedExample.config || {})
            }));

            // Set up listener for example updates
            const unsubscribe = subscribeToExample(exampleId, (updatedExample) => {
                if (updatedExample.status !== STATUS.COMPLETED) {
                    setIsLoading(true);
                } else {
                    setIsLoading(false);
                }
                setExample(updatedExample);
            });
            return () => unsubscribe();
        };

        fetchExample();
    }, [exampleId]);

    useEffect(() => {
        if (isLoadModalOpen) {
            const fetchSavedExamples = async () => {
                setSavedExamplesInfo(await getSavedExamplesInfo(userId));
            };
            fetchSavedExamples();
        }
    }, [isLoadModalOpen]);

    const handleConfigChange = (field, value) => {
        setConfig(prev => ({
            ...prev,
            [field]: value
        }));
    };

    const handleSubmit = async () => {
        const variantId = await createVariant(exampleId, config);
        navigate(`/customize/${variantId}`);
    };

    
    const handleDownload = async () => {
        try {
            const response = await fetch(example.imageUrl, {
                mode: 'cors',
                headers: {
                    'Origin': window.location.origin
                }
            });
            const blob = await response.blob();
            
            // Create a temporary link element
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            
            // Extract filename from URL or use a default
            const filename = example.imageUrl.split('/').pop() || 'fractal.png';
            link.download = filename;
            
            // Programmatically click the link to trigger download
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error("Error downloading image:", error);
        }
    };

    const handleSave = async () => {
        // Create sorted string representations for comparison
        const sortedConfig = JSON.stringify(config, Object.keys(config).sort());
        const sortedExampleConfig = JSON.stringify(example.config, Object.keys(example.config).sort());
        
        if (sortedConfig !== sortedExampleConfig) {
            alert("Your config has changed, please compute with the latest settings before saving.");
            return;
        }
        
        await saveExample(userId, exampleId, saveName);
        setIsSaveModalOpen(false);
        setSaveName("");
    };

    const handleLoad = (exampleId) => {
        navigate(`/customize/${exampleId}`);
        setIsLoadModalOpen(false);
    };

    return (
        <Layout>
            <Container maxW="container.xl" py={20}>
                <Stack 
                    direction={{ base: 'column', lg: 'row' }} 
                    spacing={8} 
                    align="start"
                >
                    <VStack 
                        flex="1" 
                        spacing={6} 
                        align="stretch"
                        w={{ base: "100%", lg: "auto" }}
                    >
                        <Heading size="lg">Customize your beautiful math</Heading>

                        <Stack direction="row" spacing={4}>
                            <Button
                                colorScheme="blue"
                                onClick={() => setIsSaveModalOpen(true)}
                            >
                                Save
                            </Button>
                            <Button
                                colorScheme="blue"
                                onClick={() => setIsLoadModalOpen(true)}
                            >
                                Load
                            </Button>
                        </Stack>

                        <CustomizationForm
                            config={config}
                            onConfigChange={handleConfigChange}
                            onSubmit={handleSubmit}
                            example={example}
                            isLoading={isLoading}
                        />
                    </VStack>
                    <Box flex="1" w={{ base: "100%", lg: "auto" }}>
                        {example && example.imageUrl && (
                            <>
                                <Image
                                    src={example.imageUrl}
                                    w="full"
                                    h="auto"
                                />
                                {
                                <Button
                                    mt={4}
                                    colorScheme="blue"
                                    leftIcon={<DownloadIcon />}
                                    onClick={handleDownload}
                                    w="full"
                                >
                                    Download image
                                </Button>
                                }
                            </>
                        )}
                    </Box>
                </Stack>
            </Container>
            <SaveConfigModal
                isOpen={isSaveModalOpen}
                onClose={() => setIsSaveModalOpen(false)}
                onSave={handleSave}
                saveName={saveName}
                onSaveNameChange={setSaveName}
            />
            <LoadConfigModal
                isOpen={isLoadModalOpen}
                onClose={() => setIsLoadModalOpen(false)}
                savedExamplesInfo={savedExamplesInfo}
                onExampleSelect={handleLoad}
            />
        </Layout>
    );
}

export default CustomizationPage;