import React from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    FormControl,
    FormLabel,
    Input,
    Button,
    Box,
} from "@chakra-ui/react";

function SaveConfigModal({ isOpen, onClose, onSave, saveName, onSaveNameChange }) {
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Save Configuration</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                    <FormControl>
                        <FormLabel>Configuration Name</FormLabel>
                        <Input
                            value={saveName}
                            onChange={(e) => onSaveNameChange(e.target.value)}
                            placeholder="Enter a name for this configuration"
                        />
                    </FormControl>
                </ModalBody>
                <Box p={4}>
                    <Button colorScheme="blue" mr={3} onClick={onSave}>
                        Save
                    </Button>
                    <Button onClick={onClose}>Cancel</Button>
                </Box>
            </ModalContent>
        </Modal>
    );
}

export default SaveConfigModal; 