import React, { useState } from "react";
import {
    VStack,
    FormControl,
    FormLabel,
    Select,
    Input,
    Link,
} from "@chakra-ui/react";
import SubmitButton from "../SubmitButton";
import ColormapModal from './ColormapModal';

// Move the constants from CustomizationPage.js to here
const LEGEND_POSITIONS = [
    { label: "Bottom", value: "bottom" },
    { label: "Top", value: "top" },
];

const FONT_COLORS = [
    { label: "Black", value: "#000000" },
    { label: "White", value: "#FFFFFF" },
];

const RESOLUTION_OPTIONS = [
    { label: "1500x1500", value: 100 },
    { label: "3000x3000", value: 200 },
];

const COLORMAPS = [
    { label: "Blues", value: "Blues" },
    { label: "BrBG", value: "BrBG" },
    { label: "BuGn", value: "BuGn" },
    { label: "BuPu", value: "BuPu" },
    { label: "BWR", value: "bwr" },
    { label: "Cividis", value: "cividis" },
    { label: "Coolwarm", value: "coolwarm" },
    { label: "GnBu", value: "GnBu" },
    { label: "Greens", value: "Greens" },
    { label: "Greys", value: "Greys" },
    { label: "Inferno", value: "inferno" },
    { label: "Magma", value: "magma" },
    { label: "Oranges", value: "Oranges" },
    { label: "OrRd", value: "OrRd" },
    { label: "PiYG", value: "PiYG" },
    { label: "Plasma", value: "plasma" },
    { label: "PRGn", value: "PRGn" },
    { label: "PuBu", value: "PuBu" },
    { label: "PuBuGn", value: "PuBuGn" },
    { label: "PuOr", value: "PuOr" },
    { label: "PuRd", value: "PuRd" },
    { label: "Purples", value: "Purples" },
    { label: "RdBu", value: "RdBu" },
    { label: "RdGy", value: "RdGy" },
    { label: "RdPu", value: "RdPu" },
    { label: "Reds", value: "Reds" },
    { label: "RdYlBu", value: "RdYlBu" },
    { label: "RdYlGn", value: "RdYlGn" },
    { label: "Seismic", value: "seismic" },
    { label: "Spectral", value: "Spectral" },
    { label: "Viridis", value: "viridis" },
    { label: "YlGn", value: "YlGn" },
    { label: "YlGnBu", value: "YlGnBu" },
    { label: "YlOrBr", value: "YlOrBr" },
    { label: "YlOrRd", value: "YlOrRd" },
];

function CustomizationForm({ config, onConfigChange, onSubmit, example, isLoading }) {
    const [isColormapModalOpen, setIsColormapModalOpen] = useState(false);

    const hasChanged = (field) => {
        if (example.config?.[field] === undefined || config[field] === undefined) return false;
        return example.config[field] !== config[field];
    };

    return (
        <>
            <VStack spacing={6} align="stretch" w={{ base: "100%", lg: "auto" }}>
                <FormControl>
                    <FormLabel>Title</FormLabel>
                    <Input
                        value={config.title}
                        onChange={(e) => onConfigChange('title', e.target.value)}
                        placeholder="Enter a title"
                        bg="whiteAlpha.700"
                    />
                    {hasChanged('title') && (
                        <FormLabel color="blue.500" fontSize="sm" mt={1}>Has changed</FormLabel>
                    )}
                </FormControl>

                <FormControl>
                    <FormLabel>Font Color</FormLabel>
                    <Select
                        value={config.fontColor}
                        onChange={(e) => onConfigChange('fontColor', e.target.value)}
                        bg="whiteAlpha.700"
                    >
                        {FONT_COLORS.map(color => (
                            <option key={color.value} value={color.value}>
                                {color.label}
                            </option>
                        ))}
                    </Select>
                    {hasChanged('fontColor') && (
                        <FormLabel color="blue.500" fontSize="sm" mt={1}>Has changed</FormLabel>
                    )}
                </FormControl>

                <FormControl>
                    <FormLabel>Legend Position</FormLabel>
                    <Select
                        value={config.legendPosition}
                        onChange={(e) => onConfigChange('legendPosition', e.target.value)}
                        bg="whiteAlpha.700"
                    >
                        {LEGEND_POSITIONS.map(pos => (
                            <option key={pos.value} value={pos.value}>
                                {pos.label}
                            </option>
                        ))}
                    </Select>
                    {hasChanged('legendPosition') && (
                        <FormLabel color="blue.500" fontSize="sm" mt={1}>Has changed</FormLabel>
                    )}
                </FormControl>

                <FormControl>
                    <FormLabel>
                        Color Scheme{" "}
                        <Link
                            color="blue.500"
                            onClick={() => setIsColormapModalOpen(true)}
                            textDecoration="underline"
                            fontSize="sm"
                        >
                            see options
                        </Link>
                    </FormLabel>
                    <Select
                        value={config.colormap}
                        onChange={(e) => onConfigChange('colormap', e.target.value)}
                        bg="whiteAlpha.700"
                    >
                        {COLORMAPS.map(cmap => (
                            <option key={cmap.value} value={cmap.value}>
                                {cmap.label}
                            </option>
                        ))}
                    </Select>
                    {hasChanged('colormap') && (
                        <FormLabel color="blue.500" fontSize="sm" mt={1}>Has changed</FormLabel>
                    )}
                </FormControl>

                <FormControl>
                    <FormLabel>Resolution</FormLabel>
                    <Select
                        value={config.dpi}
                        onChange={(e) => onConfigChange('dpi', Number(e.target.value))}
                        bg="whiteAlpha.700"
                    >
                        {RESOLUTION_OPTIONS.map(option => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </Select>
                    {hasChanged('dpi') && (
                        <FormLabel color="blue.500" fontSize="sm" mt={1}>Has changed</FormLabel>
                    )}
                </FormControl>

                <SubmitButton
                    isLoading={isLoading}
                    loadingText="Generating"
                    onClick={onSubmit}
                >
                    Compute with these settings
                </SubmitButton>
            </VStack>

            <ColormapModal 
                isOpen={isColormapModalOpen} 
                onClose={() => setIsColormapModalOpen(false)} 
            />
        </>
    );
}

export default CustomizationForm; 