import React from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Text,
    SimpleGrid,
    Card,
    CardBody,
    Stack,
} from "@chakra-ui/react";

function LoadConfigModal({ isOpen, onClose, savedExamplesInfo, onExampleSelect }) {
    return (
        <Modal isOpen={isOpen} onClose={onClose} size="xl">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Load a saved configuration</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                    <SimpleGrid columns={2} spacing={4}>
                        {savedExamplesInfo.map((config) => (
                            <Card 
                                key={config.exampleId + config.saveName}
                                cursor="pointer"
                                onClick={() => onExampleSelect(config.exampleId)}
                                _hover={{ transform: 'scale(1.02)', transition: 'transform 0.2s' }}
                            >
                                <CardBody>
                                    <Stack>
                                        <Text fontWeight="bold">{config.saveName}</Text>
                                    </Stack>
                                </CardBody>
                            </Card>
                        ))}
                    </SimpleGrid>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}

export default LoadConfigModal; 