import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Image,
} from "@chakra-ui/react";

function ColormapModal({ isOpen, onClose }) {
    return (
        <Modal isOpen={isOpen} onClose={onClose} size="xl">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Colormap Options</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                    <Image src="/colormaps.png" alt="Colormap options" />
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}

export default ColormapModal; 