import React, { createContext, useState, useContext } from 'react';

const UserContext = createContext();

export function UserProvider({ children }) {
    const [userId] = useState(getUserId());

    return (
        <UserContext.Provider value={userId}>
            {children}
        </UserContext.Provider>
    );
}

export function useUser() {
    const context = useContext(UserContext);
    if (context === undefined) {
        throw new Error('useUser must be used within a UserProvider');
    }
    return context;
} 


export function getUserId() {
    const cookieName = "beautifulMathUserId";
    let userId = document.cookie.replace(
        new RegExp(`(?:(?:^|.*;\\s*)${cookieName}\\s*\\=\\s*([^;]*).*$)|^.*$`),
        "$1"
    );

    if (!userId) {
        userId = "user_" + Math.random().toString(36).substr(2, 9);
        document.cookie = `${cookieName}=${userId};max-age=31536000;path=/`; // 1 year expiry
    }
    return userId;
}